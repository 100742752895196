import { ReactNode, createContext, useState } from "react";

import AlertModal from "./AlertModal";

interface AlertButton {
  text?: string;
  onClick?: (value?: string) => void;
}

interface AlertOptions {
  title: string;
  message?: string;
  buttons?: AlertButton[];
}

const AlertContext = createContext<{
  showAlert: boolean;
  alertOptions?: AlertOptions | null;
  openAlert: (title: string, message?: string, buttons?: AlertButton[]) => void;
  closeAlert: () => void;
}>({
  showAlert: false,
  alertOptions: null,
  openAlert: () => null,
  closeAlert: () => null,
});

const AlertProvider = ({ children }: { children: ReactNode }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertOptions, setAlertOptions] = useState<AlertOptions | null>(null);

  const openAlert = (title: string, message?: string, buttons?: AlertButton[]) => {
    setAlertOptions({ title, message, buttons });
    setShowAlert(true);
  };
  const closeAlert = () => {
    setAlertOptions(null);
    setShowAlert(false);
  };

  return (
    <AlertContext.Provider value={{ showAlert, alertOptions, openAlert, closeAlert }}>
      <AlertModal />
      {children}
    </AlertContext.Provider>
  );
};

export { AlertContext, AlertProvider };
