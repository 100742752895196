import { MouseEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Button, Grid, Popover, TextField } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { formatISO, isAfter } from "date-fns";
import { useFormik } from "formik";
import * as Yup from "yup";

import { CommonDateRangeSelectionButtons } from "@APP/components";
import { CHART_FILTER_FIELDS, REPORT_FILTERS, commonFormatDate } from "@APP/constants";
import { useAlert, useHandleErrorCodes } from "@APP/hooks";
import {
  hideLoader,
  setReportData,
  setReportFilter,
  setReportFrom,
  setReportTo,
  showLoader,
} from "@APP/redux";
import { API } from "@APP/services";
import { Report, ReportInterval, RtpReportData } from "@APP/types";
import {
  formatErrorMessage,
  generateInitialDatesFromReport,
  stripDuplicates,
  appropriateGrouping,
} from "@APP/utils";

import { CommonChartButtonProps } from "../chartHeaderButtons";

type NewReportButtonProps = CommonChartButtonProps & {
  dataProps: NewReportPayload;
};

export type NewReportPayload = ReportInterval & {
  chartFilters: CHART_FILTER_FIELDS[];
  label?: string;
  report: Report;
};

const reportDateValidationSchema = Yup.object().shape({
  from: Yup.date().required("Start date is required"),
  to: Yup.date().test("endDate", "End date must be after start date", (value, context) => {
    return value ? isAfter(value, context.parent.from) : false;
  }),
});

export const NewReportButton = ({ classes, dataProps, reportName }: NewReportButtonProps) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const handleErrorCodes = useHandleErrorCodes();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [startDatePickerOpen, setStartDatePickerOpen] = useState(false);
  const [endDatePickerOpen, setEndDatePickerOpen] = useState(false);

  const { from, to, chartFilters } = dataProps;

  const handlePopoverClick = (event: MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(anchorEl ? null : event.currentTarget);

  const open = Boolean(anchorEl);

  const filterButtonLabel = "New report";

  const handleOnSubmit = async ({
    from,
    to,
    chartFilters,
  }: {
    from: string;
    to: string;
    chartFilters: CHART_FILTER_FIELDS[];
  }) => {
    const { report } = dataProps;

    const filter = {
      from: formatISO(new Date(from)),
      to: formatISO(new Date(to)),
      group: appropriateGrouping(from, to),
    };

    try {
      dispatch(showLoader());
      const response = await API.getReport(reportName, filter);
      const datesFromReport = generateInitialDatesFromReport(reportName, response.data);

      dispatch(setReportData({ reportName, data: response.data }));
      dispatch(setReportFrom(reportName, from));
      dispatch(setReportTo(reportName, to));

      const filteredData = [...(response.data as RtpReportData[])];

      chartFilters.forEach((selectedFilter) => {
        if (report.filters[REPORT_FILTERS[selectedFilter]]) {
          const selectedValues = stripDuplicates(
            filteredData.map(({ [selectedFilter]: value }) => value as string),
          );

          dispatch(
            setReportFilter({
              reportName,
              filterName: REPORT_FILTERS[selectedFilter],
              selectedValues,
            }),
          );
        }
      });

      dispatch(
        setReportFilter({
          reportName,
          filterName: REPORT_FILTERS.dates,
          selectedValues: datesFromReport,
        }),
      );
    } catch (error: any) {
      const errorData = error?.response?.data;
      const isHandled = handleErrorCodes(errorData?.errorCode);

      if (!isHandled) return;
      alert("Error", formatErrorMessage(error));
    } finally {
      dispatch(hideLoader());
    }
    setAnchorEl(null);
  };

  const { touched, errors, handleSubmit, isSubmitting, values, setFieldValue } = useFormik({
    initialValues: {
      from: from,
      to: to,
      chartFilters: chartFilters,
    },
    validationSchema: reportDateValidationSchema,
    onSubmit: handleOnSubmit,
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box className={classes?.buttonContainer}>
        <Button variant="text" size="small" color="primary" onClick={handlePopoverClick}>
          {filterButtonLabel}
        </Button>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClick}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}>
          <form onSubmit={handleSubmit}>
            <Box m={1}>
              <Grid container direction="column" justifyContent="space-between" spacing={2}>
                <Grid item>
                  <CommonDateRangeSelectionButtons
                    fieldValueSetter={setFieldValue}
                    contentJustify="space-between"
                    values={values}
                    handleOnSubmit={handleOnSubmit}
                  />
                </Grid>
                <Grid item>
                  <DatePicker
                    renderInput={(props) => (
                      <TextField
                        error={Boolean(touched.from && errors.from)}
                        helperText={touched.from && errors.from}
                        fullWidth
                        size="small"
                        onClick={() => setStartDatePickerOpen(true)}
                        {...props}
                      />
                    )}
                    onChange={(value) => setFieldValue("from", value)}
                    value={values.from}
                    label="Start Date"
                    inputFormat={commonFormatDate}
                    disableFuture
                    open={startDatePickerOpen}
                    onOpen={() => setStartDatePickerOpen(true)}
                    onClose={() => setStartDatePickerOpen(false)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DatePicker
                    renderInput={(props) => (
                      <TextField
                        error={Boolean(touched.to && errors.to)}
                        helperText={touched.to && errors.to}
                        fullWidth
                        size="small"
                        onClick={() => setEndDatePickerOpen(true)}
                        {...props}
                      />
                    )}
                    onChange={(value) => setFieldValue("to", value)}
                    value={values.to}
                    label="End Date"
                    inputFormat={commonFormatDate}
                    disableFuture
                    open={endDatePickerOpen}
                    onOpen={() => setEndDatePickerOpen(true)}
                    onClose={() => setEndDatePickerOpen(false)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    type="submit"
                    fullWidth
                    variant="contained">
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Popover>
      </Box>
    </LocalizationProvider>
  );
};

export default NewReportButton;
