import { useDispatch } from "react-redux";
import { Box, Button } from "@mui/material";

import { ArraySetterPayload, CommonChartButtonProps } from "@APP/components";
import { setReportFilter } from "@APP/redux";

export type ViewAllButtonProps = CommonChartButtonProps & {
  dataProps: ArraySetterPayload[];
};

export const ViewAllButton = ({
  buttonLabel,
  classes,
  dataProps,
  reportName,
}: ViewAllButtonProps) => {
  const dispatch = useDispatch();

  const handleSelectAll = () =>
    dataProps.map(({ filterName, dataArray }) =>
      dispatch(
        setReportFilter({
          reportName,
          filterName,
          selectedValues: dataArray,
        }),
      ),
    );

  return (
    <Box className={classes?.buttonContainer}>
      <Button variant="text" size="small" color="primary" onClick={handleSelectAll}>
        {buttonLabel}
      </Button>
    </Box>
  );
};
