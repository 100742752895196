import { useSelector } from "react-redux";
import { Grid } from "@mui/material";

import {
  ChartContainer,
  FilterContainer,
  Page,
  PieChart,
  UsersActiveHeaderButton,
  UsersDeactiveHeaderButton,
  UsersRegisteredByBankHeaderButton,
  UsersRegisteredByErpHeaderButton,
} from "@APP/components";
import { ChartComponent } from "@APP/components/charts/ChartContainer";
import { CHART_FILTER_FIELDS, REPORT_NAMES } from "@APP/constants";
import { AppState, setUsersVisualisations } from "@APP/redux";
import { reportDataFormatter } from "@APP/utils";

export const UserDashboardView = () => {
  const {
    usersVisualisations,
    usersDeactive,
    usersActive,
    usersRegisteredByBank,
    usersRegisteredByErp,
  } = useSelector(
    ({
      userAdmin: {
        usersVisualisations,
        reports: { usersDeactive, usersActive, usersRegisteredByBank, usersRegisteredByErp },
      },
    }: AppState) => ({
      usersVisualisations,
      usersDeactive,
      usersActive,
      usersRegisteredByBank,
      usersRegisteredByErp,
    }),
  );

  const userCharts: ChartComponent[] = [
    {
      reportName: REPORT_NAMES.usersDeactive,
      label: "Deactive users by Partner",
      component: PieChart,
      report: usersDeactive,
      headerButton: UsersDeactiveHeaderButton,
      chartFilters: [CHART_FILTER_FIELDS.partner],
    },
    {
      reportName: REPORT_NAMES.usersActive,
      label: "Active users by Partner",
      component: PieChart,
      report: usersActive,
      headerButton: UsersActiveHeaderButton,
      chartFilters: [CHART_FILTER_FIELDS.partner],
    },
    {
      reportName: REPORT_NAMES.usersRegisteredByErp,
      label: "Users registered by Erp",
      component: PieChart,
      report: usersRegisteredByErp,
      headerButton: UsersRegisteredByErpHeaderButton,
      chartFilters: [CHART_FILTER_FIELDS.partner, CHART_FILTER_FIELDS.erp],
    },
    {
      reportName: REPORT_NAMES.usersRegisteredByBank,
      label: "Users registered by Bank",
      component: PieChart,
      report: usersRegisteredByBank,
      headerButton: UsersRegisteredByBankHeaderButton,
      size: 12,
      chartFilters: [CHART_FILTER_FIELDS.partner, CHART_FILTER_FIELDS.bank],
    },
  ];

  return (
    <Page title="User Dashboard">
      <Grid container spacing={3} justifyContent="flex-start">
        <Grid item xs={12} md={6}>
          <FilterContainer
            components={userCharts}
            visualisations={usersVisualisations}
            setVisualisations={setUsersVisualisations}
          />
        </Grid>
        <ChartContainer
          components={userCharts}
          visualisations={usersVisualisations}
          dataFormatter={reportDataFormatter}
        />
      </Grid>
    </Page>
  );
};

export default UserDashboardView;
