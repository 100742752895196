import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button, Container, useMediaQuery, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import MenuIcon from "@mui/icons-material/Menu";

import { AppRouter, RouteConfig } from "@APP/navigation";
import { AppState } from "@APP/redux";
import { Loader } from "@APP/components";
import { CLOSE_SIDEBAR_WIDTH, OPEN_SIDEBAR_WIDTH, TOP_BAR_HEIGHT } from "@APP/styles";

import NavBar from "./SideBar";
import TopBar from "./TopBar";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: TOP_BAR_HEIGHT,
  },
  wrapperWithFullSideBar: {
    [theme.breakpoints.up("sm")]: {
      paddingLeft: OPEN_SIDEBAR_WIDTH,
      transition: theme.transitions.create("paddingLeft", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  wrapperWithSmallSideBar: {
    [theme.breakpoints.up("sm")]: {
      paddingLeft: CLOSE_SIDEBAR_WIDTH,
      transition: theme.transitions.create("paddingLeft", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto",
  },
  burgerButtonContainer: {
    position: "absolute",
    zIndex: 10000,
    [theme.breakpoints.down("sm")]: {
      left: 10,
      height: TOP_BAR_HEIGHT,
      display: "flex",
      alignItems: "center",
    },
    [theme.breakpoints.up("sm")]: {
      top: TOP_BAR_HEIGHT - 24,
    },
  },
  burgerOpened: {
    [theme.breakpoints.up("sm")]: {
      left: OPEN_SIDEBAR_WIDTH - 22,
      transition: theme.transitions.create("left", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  burgerClosed: {
    [theme.breakpoints.up("sm")]: {
      left: CLOSE_SIDEBAR_WIDTH - 22,
      transition: theme.transitions.create("left", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  burgerButton: {
    padding: "10px",
    borderRadius: "50%",
    minWidth: 0,
  },
  routerContent: {
    height: `calc(100vh - ${TOP_BAR_HEIGHT}px)`,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const DashboardLayout = ({ routes }: { routes: RouteConfig[] }) => {
  const { isLoading } = useSelector(({ dashboardApp: { isLoading } }: AppState) => ({ isLoading }));
  const classes = useStyles();
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const [isFullSideBar, setIsFullSideBar] = useState(false);

  const { user } = useSelector(({ auth: { user } }: AppState) => ({
    user,
  }));

  useEffect(() => {
    setIsFullSideBar(!isPhone);
  }, [isPhone]);

  return (
    <Box className={classes.root}>
      {isLoading && <Loader isFullSideBar={isFullSideBar} />}
      <Box
        className={clsx(classes.burgerButtonContainer, {
          [classes.burgerOpened]: isFullSideBar,
          [classes.burgerClosed]: !isFullSideBar,
        })}>
        <Button
          className={classes.burgerButton}
          variant="contained"
          color="primary"
          onClick={() => setIsFullSideBar(!isFullSideBar)}>
          <MenuIcon />
        </Button>
      </Box>
      <TopBar username={user?.org?.primaryContact.name} isFullSideBar={isFullSideBar} />
      <NavBar onMobileClose={() => setIsFullSideBar(false)} isFullSideBar={isFullSideBar} />
      <Box
        className={clsx(classes.wrapper, {
          [classes.wrapperWithFullSideBar]: isFullSideBar,
          [classes.wrapperWithSmallSideBar]: !isFullSideBar,
        })}>
        <Box className={classes.contentContainer}>
          <Box className={classes.content}>
            <Container className={classes.routerContent} maxWidth="xl">
              <AppRouter routes={routes} />
            </Container>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardLayout;
