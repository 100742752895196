import { useHistory } from "react-router-dom";
import { Box } from "@mui/material";

import { ResultNotification, Page, FooterActionsButtons } from "@APP/components";
import { SCREEN_PATHS } from "@APP/navigation";

export const UpdateUserSuccessView = () => {
  const history = useHistory();

  const handleNavigateToDashboard = () => history.push(SCREEN_PATHS.ACTIVITY_DASHBOARD);
  const handleNavigateToUpdateAnotherUser = () => history.push(SCREEN_PATHS.UPDATE_USER);

  return (
    <Page title="Update User">
      <ResultNotification type="success">This user was successfully updated</ResultNotification>

      <Box mt={7}>
        <FooterActionsButtons
          backButtonText="Back to Dashboard"
          handleBackButton={handleNavigateToDashboard}
          continueButtonText="Update another user"
          handleContinue={handleNavigateToUpdateAnotherUser}
        />
      </Box>
    </Page>
  );
};

export default UpdateUserSuccessView;
