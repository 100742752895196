import {
  Partners,
  REPORT_TRANSACTION_STATUSES,
  ReportGroupIntervals,
  SORT_TYPES,
  TimeBuckets,
  REPORT_ERPS,
} from "@APP/constants";

export interface LoginData {
  token: string;
  emailVerified: boolean;
  phoneVerified: boolean;
}

export interface UserInfoData {
  username: string;
  phone: string;
  orgName?: string;
  roles: string[];
  org: null | UserOrganisation;
  verifiedEmail: boolean;
}

export interface UserOrganisation {
  id: string;
  name: string;
  companyInfo: ContactInfo;
  primaryContact: ContactInfo;
  otherContacts: any[];
  taxInfo: {
    vatRegistrationNumber: string;
    utrNumber: string;
    defaultVatRate: string;
    defaultCurrency: string;
  };
  SIC: string[];
  logo: string;
  orgType: string;
  pricingPlan: string;
  registrationDate?: string;
}

export interface ContactInfo {
  id: string;
  name: string;
  email: string;
  telephone: string;
  address: {
    lines: string[];
    postcode: string;
    city: string;
    country: string;
  };
}

export interface Contact {
  id: ContactId;
  name: string;
  email?: string;
  billingAddress?: Address;
  vatRate: VATRate;
  mobile: string;
}

export type ContactId = {
  source?: string;
  externalId: string;
};

export type Address = {
  addressLines?: [string];
  postcode?: string;
  city?: string;
  countryId?: string;
};
export interface Custodian {
  country: string;
  features: string[];
  fullName: string;
  id: string;
  logo: string;
  shortName: string;
  type: string;
  website: string;
  tags?: string[];
  description?: string;
  provider: string;
}

export type CustodianType = "Bank" | "AccountingPackage";

export type ERPConsentInitData = {
  redirectUrl?: string;
  consentId: string;
};

export type ERPConsentData = {
  consentId: string;
  context: {
    custodianId: string;
    customerId: string;
    status: string;
    created: string;
    consentType: string;
    externalId: string;
  };
  consentBody: string;
};

export type VATRate =
  | "ECServicesZeroRated"
  | "ReverseChargeServices"
  | "ReverseCharge"
  | "ECGoodsZeroRated"
  | "Standard"
  | "ReverseChargePhonesAndComputerChips"
  | "ExemptFromVAT"
  | "ECGoodsStandard"
  | "ZeroRated"
  | "ECServicesStandard"
  | "Reduced"
  | "NoVATApplicable";

export type InvoiceStatus = "Draft" | "Approved" | "Void" | "Sent" | "Paid" | "PartiallyPaid";

export type InvoiceLineItem = {
  index: number;
  id: string;
  amountTaxExclusive: Amount;
  category: string;
  description: string;
  vatRate: VATRate;
  ledgerCode: number;
};

export type Invoice = {
  entityDetails: {
    source: string;
    externalId: string;
  };
  lineItems: InvoiceLineItem[];
  dateIssued: string;
  dateTimeIssued: string;
  dueDate: string;
  reference: string;
  totalAmountTaxExclusive: Amount;
  totalAmountTaxInclusive: Amount;
  paidDate: string;
  plannedPaymentDate: string;
  status: InvoiceStatus;
  attachments?: [
    {
      id: string;
      name: string;
      uri: string;
      contentType: string;
      createdAt: string;
    },
  ];
  notes: string[];
  name: string;
  description: string;
};

export type Receivable = Invoice & {
  id: string;
  customerContact: Contact;
  invoiceType: "Receivable";
  remainingAmountTaxExclusive: Amount;
};

export type RTP = {
  amount: Amount;
  channel: keyof typeof RTPDeliveryChannel;
  customer: Contact;
  details: {
    externalId: string;
    source: string;
  };
  id: string;
  scheduledDates?: [];
  status: RTPStatus;
  supplier: {
    SIC: string[];
    companyInfo: {
      email: string;
      id: string;
      name: string;
      telephone: string;
    };
    name: string;
    otherContacts: [];
    primaryContact: {
      id: string;
      name: string;
      email?: string;
      telephone?: string;
    };
    taxInfo: {
      defaultCurrency: string;
      defaultVatRate: string;
      utrNumber: string;
      vatRegistrationNumber: string;
    };
  };
  supplierBankDetails: {
    accountNumber: string;
    scheme: string;
  };
  receivable: {
    lineItems: Receivable["lineItems"];
    dateIssued: Receivable["dateIssued"];
    dueDate: Receivable["dueDate"];
    dateTimeIssued: Receivable["dateTimeIssued"];
    reference: Receivable["reference"];
    customerContact?: Receivable["customerContact"];
  };
};

export enum RTPStatus {
  RequestMade = "RequestMade",
  PaymentSent = "PaymentSent",
  PaymentReceived = "PaymentReceived",
  Declined = "Declined",
  Created = "Created",
  Scheduled = "Scheduled",
  Cancelled = "Cancelled",
  InProgress = "InProgress",
}

export enum RTPDeliveryChannel {
  Email = "Email",
  Sms = "Sms",
  OwnSms = "OwnSms",
  FaceToFace = "FaceToFace",
  WhatsApp = "WhatsApp",
}

export type DueDate = "Overdue" | "DueToday";

export type RTPQueryFilter = {
  dueDate?: DueDate;
  status?: keyof typeof RTPStatus | (string & {});
};

/**
 * Common pagination options used across API methods.
 */
export interface RequestPagination {
  page?: number;
  entries?: number;
}

export interface Amount {
  amount: string;
  currency: string;
}

export enum SortBy {
  dueDate = "due_date",
  amount = "amount",
}

export type SortType = keyof typeof SORT_TYPES;

export interface RequestSorting {
  sort_type?: SortType;
  sort_by?: SortBy;
}

/**
 * A set of pagination links returned in the API response.
 */
export interface ResponsePagination {
  self?: string;
  first?: string;
  prev?: string;
  next?: string;
  last?: string;
}

export type FooterActionContinueButton = "button" | "reset" | "submit";

export type UserStatus = "Active" | "Deactivated";

export enum ADMIN_ACTIONS {
  Block = "Block",
  Unblock = "Unblock",
}

export type UserInfoDataExtended = UserInfoData & {
  customerId: string;
  meta: {
    lastLoginTime: string;
  };
  password?: string;
  blocked?: boolean;
  userActive?: string;
  linkedBank?: string;
  comments?: string;
};

export type UserSearchResponse = {
  username: string;
  customerId: string;
  orgId: string;
  orgName: string;
  roles: string[];
  phone: string;
  meta: {
    lastLoginTime: null | string;
  };
};

// orgname is not camelcase as in request it is lowercase.
export type UserSearchTerms = {
  username?: string;
  orgname?: string;
  phone?: string;
};

export type ChartData = {
  name: string;
  value: number;
  color?: string;
};

export type LineData = { name: keyof typeof TimeBuckets } & {
  [key in string]: number;
}[];

export type AdminRTPFilter = {
  receivableId?: string;
  source?: string;
  requestId?: string;
  issuedFrom: string;
  issuedTo: string;
};

export enum RtpSortBy {
  email = "email",
  dateIssued = "dateIssued",
  dueDate = "dueDate",
  amount = "amount",
  status = "status",
  timeIssued = "timeIssued",
}

export type ReportInterval = {
  from: string;
  to: string;
};

export type Partner = keyof typeof Partners;

export type ReportGroupInterval = keyof typeof ReportGroupIntervals;
export type ReportTransactionStatus = keyof typeof REPORT_TRANSACTION_STATUSES;
export type ReportTransactionChannel = keyof typeof RTPDeliveryChannel;
export interface ActivityReportRequestFilter {
  from: string;
  to: string;
  partner?: Partner[];
  group?: ReportGroupInterval;
}

export type ActivityReportFilters = {
  selectedDates: string[];
  selectedPartners: Partner[];
  selectedStatus?: ReportTransactionStatus[];
  selectedChannels?: RTPDeliveryChannel[];
  selectedErps?: REPORT_ERP[];
  selectedBanks?: string[];
};

export type ActivityReportResponse = ActivityReportRequestFilter & {
  data: ReportDataType[];
};

export type Report = ActivityReportResponse & {
  group?: ReportGroupInterval;
  filters: ActivityReportFilters;
};

export type CommonReportData = {
  total: number;
  partner: keyof typeof Partners;
  group?: ReportGroupInterval;
};

export type PeakTrafficReportData = CommonReportData & {
  dateTimeBucket: string;
};

export type DeclinedReportData = CommonReportData & {
  interval: ReportInterval;
};

export type TransactionsReportData = DeclinedReportData & {
  status: ReportTransactionStatus;
};

export type ChannelsReportData = TransactionsReportData & {
  channel: RTPDeliveryChannel;
};

export type UsersActiveReportData = DeclinedReportData;
export type UsersDectiveReportData = DeclinedReportData;
export type UsersRegisteredByBankData = DeclinedReportData & {
  bank: string;
};
export type UsersRegisteredByErpData = DeclinedReportData & {
  erp: string;
};

export type PeakTrafficDataResponse = {
  totalSent: number;
  dateTimeBucket: string;
  partner: keyof typeof Partners;
};

export type RtpReportData = Partial<ChannelsReportData> &
  Required<DeclinedReportData> & {
    bank?: string;
    erp?: string;
  };

export type DateRangeButton = {
  label: string;
  from: string;
  to: string;
};

export type ReportDataType =
  | DeclinedReportData
  | TransactionsReportData
  | ChannelsReportData
  | PeakTrafficReportData
  | UsersDectiveReportData
  | UsersActiveReportData
  | UsersRegisteredByBankData
  | UsersRegisteredByErpData;

export type REPORT_ERP = keyof typeof REPORT_ERPS;
