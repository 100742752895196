import React, { ReactText } from "react";
import { Box, Card, Typography } from "@mui/material";
import { TooltipProps } from "recharts";

import { ChartComponent } from "../ChartContainer";

type Props = ChartComponent["tooltipProps"] & {
  percent?: boolean;
};

const ChartTooltip = ({
  active,
  payload,
  percent,
  valueTitle,
}: Props & TooltipProps<string, ReactText>) => {
  if (active && payload && payload.length) {
    return (
      <Card>
        <Box p={1} display="flex" flexDirection="column">
          {valueTitle ? <Typography variant="caption">{payload[0].payload.name}</Typography> : null}
          <Typography variant="caption">
            {valueTitle ?? payload[0].payload.name}: {payload[0].value}
            {percent ? "%" : ""}
          </Typography>
        </Box>
      </Card>
    );
  }

  return null;
};

export default ChartTooltip;
