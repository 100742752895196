import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  BoxProps,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ErrorIcon from "@mui/icons-material/Error";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import PersonIcon from "@mui/icons-material/Person";
import queryString from "query-string";

import { PaginationPanel } from "@APP/components";
import {
  NUMBER_OF_FIRST_PAGE_IN_TABLE_WITH_PAGINATION,
  RTP_STATUS_COLOR,
  RTP_STATUS_LABEL,
} from "@APP/constants";
import { useAlert } from "@APP/hooks";
import { SCREEN_PATHS } from "@APP/navigation";
import { API } from "@APP/services";
import { RTP } from "@APP/types";
import { formatErrorMessage } from "@APP/utils";

const useStyles = makeStyles((theme) => ({
  tableRow: {
    cursor: "pointer",
  },
  tableIcon: {
    marginRight: theme.spacing(1),
  },
  placeholderIcon: {
    marginBottom: theme.spacing(1),
  },
}));

interface Props {
  /**
   * Props for styling table container.
   */
  containerProps?: BoxProps;
  /**
   * Whether to render pagination footer.
   */
  showPagination?: boolean;
  /**
   * Number of rows per page.
   */
  initialEntries?: number;
  /**
   * Zero-based index.
   */
  initialPage?: number;
  /**
   * Text placeholder to show in case of empty RTP list.
   */
  emptyTableText?: string;
  /**
   * Pagination callback function.
   */
  onPagination?: (page: number, entries: number) => void;
}

const RTPTable = ({
  containerProps = {},
  showPagination = true,
  initialEntries = 15,
  initialPage = 0,
  emptyTableText = "No saved requests to pay",
  onPagination,
}: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const alert = useAlert();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [rtps, setRtps] = useState<RTP[]>([]);
  const [entries, setEntries] = useState(initialEntries);
  const [page, setPage] = useState(initialPage);
  const [lastPage, setLastPage] = useState(0);

  useEffect(() => {
    fetchRTPs();
    onPagination && onPagination(page, entries);
  }, [page, entries]);

  const fetchRTPs = async () => {
    try {
      const { data: rtpsData } = await API.getPaymentRequests({ page, entries });
      const { query } = queryString.parseUrl(rtpsData.links?.last ?? "");
      if (query.page) {
        const [lastPageEntries] = (query.page as string).split(";;;");
        const lastPageIndex = Number(lastPageEntries) / entries;
        setLastPage(lastPageIndex);
      }
      setRtps(rtpsData.data);
    } catch (error) {
      setRtps([]);
      setError(true);
      alert("Error", formatErrorMessage(error));
    }
    setLoading(false);
  };

  const handleOnEntriesChange = (entries: number) => {
    setPage(NUMBER_OF_FIRST_PAGE_IN_TABLE_WITH_PAGINATION);
    setEntries(entries);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleRowClick = (rtpId: string) => {
    history.push(`${SCREEN_PATHS.REQUESTS_TO_PAY}/${rtpId}`);
  };

  if (loading) {
    return <LinearProgress color="primary" />;
  }

  if (!loading && !rtps.length) {
    return (
      <Box textAlign="center" p={3} {...containerProps}>
        <ErrorIcon className={classes.placeholderIcon} fontSize="large" color="action" />
        <Typography variant="body2">
          {error ? "There was an error when loading requests to pay" : emptyTableText}
        </Typography>
      </Box>
    );
  }

  return (
    <Box {...containerProps}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Customer</TableCell>
            <TableCell>Total Amount</TableCell>
            <TableCell>Issued Date</TableCell>
            <TableCell>Due Date</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rtps.map((rtp) => (
            <TableRow
              key={rtp.id}
              className={classes.tableRow}
              hover
              onClick={() => handleRowClick(rtp.id)}>
              <TableCell>
                <Box display="flex" alignItems="center">
                  <PersonIcon className={classes.tableIcon} color="action" />
                  <Typography color="textPrimary" variant="body1">
                    {rtp.customer.name}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>£{Number(rtp.amount.amount).toFixed(2)}</TableCell>
              <TableCell>{rtp.receivable?.dateIssued}</TableCell>
              <TableCell>{rtp.receivable?.dueDate}</TableCell>
              <TableCell>
                <Box display="flex" alignItems="center" color={RTP_STATUS_COLOR[rtp.status]}>
                  <FiberManualRecordIcon className={classes.tableIcon} />
                  <Typography color="textPrimary" variant="body2">
                    {RTP_STATUS_LABEL[rtp.status]}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {showPagination && (
        <PaginationPanel
          entries={entries}
          onEntriesChange={handleOnEntriesChange}
          rowsPerPageOptions={[5, 10, 15, 25]}
          lastPage={lastPage}
          page={page}
          onPageChange={handlePageChange}
        />
      )}
    </Box>
  );
};

export default RTPTable;
