import { Dispatch } from "redux";
import { createActionCreator } from "deox";

import { REPORT_FILTERS, REPORT_NAMES } from "@APP/constants";
import {
  Partner,
  PeakTrafficDataResponse,
  RTP,
  ReportDataType,
  UserSearchResponse,
} from "@APP/types";

export const setUserSearchResults = createActionCreator(
  "@userAdmin/SET_USER_SEARCH_RESULTS",
  (resolve) => (results: UserSearchResponse[]) => resolve(results),
);

export const setSelectedUser = createActionCreator(
  "@userAdmin/SET_SELECTED_USER",
  (resolve) => (selectedUser: UserSearchResponse) => resolve(selectedUser),
);

export const resetSearchResults = createActionCreator(
  "@userAdmin/RESET_SEARCH_RESULTS",
  (resolve) => () => resolve(),
);

export const setUsersRTPS = createActionCreator(
  "@userAdmin/SET_USERS_RTPS",
  (resolve) => (usersRTPS: RTP[]) => resolve(usersRTPS),
);

export const setRtpIssuedTo = createActionCreator(
  "@userAdmin/SET_RTP_ISSUE_TO",
  (resolve) => (issuedTo: string) => resolve(issuedTo),
);

export const setRtpIssuedFrom = createActionCreator(
  "@userAdmin/SET_RTP_ISSUE_FROM",
  (resolve) => (issuedFrom: string) => resolve(issuedFrom),
);

export const setRTPS = createActionCreator(
  "@userAdmin/SET_RTPS",
  (resolve) => (rtps: RTP[]) => resolve(rtps),
);

export const setActivityVisualisations = createActionCreator(
  "@userAdmin/SET_ACTIVITY_VISUALISATIONS",
  (resolve) => (visualisations: string[]) => resolve(visualisations),
);

export const setUsersVisualisations = createActionCreator(
  "@userAdmin/SET_USER_VISUALISATIONS",
  (resolve) => (visualisations: string[]) => resolve(visualisations),
);

export const clearSearchResults = () => (dispatch: Dispatch) => {
  dispatch(resetSearchResults());
  dispatch(setUserSearchResults([]));
};

export const setReportFrom = createActionCreator(
  "@userAdmin/SET_REPORT_FROM",
  (resolve) => (reportName: keyof typeof REPORT_NAMES, from: string) =>
    resolve({ reportName, from }),
);

export const setReportTo = createActionCreator(
  "@userAdmin/SET_REPORT_TO",
  (resolve) => (reportName: keyof typeof REPORT_NAMES, to: string) => resolve({ reportName, to }),
);

export const setSelectedDates = createActionCreator(
  "@userAdmin/SET_SELECTED_DATES",
  (resolve) => (reportName: keyof typeof REPORT_NAMES, selectedDates: string[]) =>
    resolve({ reportName, selectedDates }),
);

export const setSelectedPartners = createActionCreator(
  "@userAdmin/SET_SELECTED_PARTNERS",
  (resolve) => (reportName: keyof typeof REPORT_NAMES, selectedPartners: Partner[]) =>
    resolve({ reportName, selectedPartners }),
);

export const setReportData = createActionCreator(
  "@userAdmin/SET_REPORT_DATA",
  (resolve) =>
    ({
      reportName,
      data,
    }: {
      reportName: keyof typeof REPORT_NAMES;
      data: ReportDataType[] | PeakTrafficDataResponse[];
    }) =>
      resolve({ reportName, data }),
);

export const setReportFilter = createActionCreator(
  "@userAdmin/SET_REPORT_FILTER",
  (resolve) =>
    ({
      reportName,
      filterName,
      selectedValues,
    }: {
      reportName: keyof typeof REPORT_NAMES;
      filterName: REPORT_FILTERS;
      selectedValues?: string[];
    }) =>
      resolve({ reportName, filterName, selectedValues }),
);
