import { ChangeEvent, Fragment, MouseEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import {
  Box,
  Card,
  Collapse,
  IconButton,
  Popover,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import FilterListIcon from "@mui/icons-material/FilterList";

import { SCREEN_PATHS } from "@APP/navigation";
import { hideLoader, setSelectedUser, setUserSearchResults, showLoader } from "@APP/redux";
import { CommonTextField, ScreenHeader, SortIcon, Table } from "@APP/components";
import { useAlert, useHandleErrorCodes } from "@APP/hooks";
import { API } from "@APP/services";
import { tableStyles as useStyles } from "@APP/styles";
import { formatCurrency, formatErrorMessage } from "@APP/utils";
import { RTP_STATUS_LABEL } from "@APP/constants";
import { RtpSortBy, SortType, UserSearchTerms } from "@APP/types";

import { DetailPanel } from "./DetailPanel";
import { RTPWithDisplaying } from "./RTPDashboardView";

type Props = {
  data: RTPWithDisplaying[];
  rtpSort: RtpSortBy;
  sortType: SortType;
  entries: number;
  page: number;
  lastPage: number;
  setEntries: (entries: number) => void;
  setPage: (page: number) => void;
  clearSearchFilter: () => void;
  searchData: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSorting: (id: RtpSortBy) => void;
  handleOnEntriesChange: (entries: number) => void;
};

interface HeadCell {
  id: RtpSortBy;
  rightAlign: boolean;
  disablePadding: boolean;
  label: string;
}

const HEADER_CELL_ITEMS: HeadCell[] = [
  { id: RtpSortBy.email, rightAlign: false, disablePadding: false, label: "Username" },
  { id: RtpSortBy.dateIssued, rightAlign: false, disablePadding: false, label: "Issue Date" },
  { id: RtpSortBy.timeIssued, rightAlign: false, disablePadding: false, label: "Issue Time" },
  { id: RtpSortBy.dueDate, rightAlign: false, disablePadding: false, label: "Due Date" },
  { id: RtpSortBy.amount, rightAlign: true, disablePadding: false, label: "Amount" },
  { id: RtpSortBy.status, rightAlign: false, disablePadding: false, label: "Status" },
];

const RTPDashboardList = ({
  data,
  rtpSort,
  sortType,
  entries,
  page,
  lastPage,
  setPage,
  clearSearchFilter,
  searchData,
  handleSorting,
  handleOnEntriesChange,
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const alert = useAlert();
  const handleErrorCodes = useHandleErrorCodes();
  const [expanded, setExpanded] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const popoverOpen = Boolean(anchorEl);

  const handlePopoverClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    clearSearchFilter();
  };

  const handleNavigateToUserOrUpdateUser = async (userSearchTerms: UserSearchTerms) => {
    try {
      dispatch(showLoader());
      const { username, phone, orgname } = userSearchTerms;
      const pagination = { page: 0, entries: 10 };
      let searchTerms = {} as UserSearchTerms;

      // BE is case sensitive
      if (username?.length) searchTerms.username = username.toLowerCase();
      if (phone?.length) searchTerms.phone = phone.toLowerCase();
      if (orgname?.length) searchTerms.orgname = orgname.toLowerCase();

      const { data: selectedUserData } = await API.getSearchedUsers(searchTerms, pagination);

      dispatch(setUserSearchResults(selectedUserData));

      if (selectedUserData.length === 1) {
        dispatch(setSelectedUser(selectedUserData[0]));
        history.push(`${SCREEN_PATHS.UPDATE_USER}/${selectedUserData[0].customerId}`);
      }

      if (selectedUserData.length > 1) {
        history.push(SCREEN_PATHS.UPDATE_USER);
      }
    } catch (error: any) {
      dispatch(setUserSearchResults([]));
      const errorData = error?.response?.data;
      const isHandled = handleErrorCodes(errorData?.errorCode);

      if (!isHandled) return;
      alert("Error", formatErrorMessage(error));
    } finally {
      dispatch(hideLoader());
    }
  };

  const renderHeader = () => (
    <TableRow className={classes.tableHead}>
      <TableCell padding="checkbox"></TableCell>
      {HEADER_CELL_ITEMS.map(({ id, label, disablePadding }) => (
        <TableCell
          key={id}
          align="left"
          padding={disablePadding ? "none" : "normal"}
          onClick={() => handleSorting(id)}>
          <Box display="flex" alignItems="center" data-testid={`${label}-field-label`}>
            {label}
            {!!data.length && <SortIcon active={rtpSort === id} type={sortType} />}
          </Box>
        </TableCell>
      ))}
      <TableCell padding="checkbox">
        <Tooltip title="Filter list" placement="right">
          <IconButton aria-label="filter list" onClick={handlePopoverClick} size="large">
            <FilterListIcon id="filter-list-icon" />
          </IconButton>
        </Tooltip>
        <Popover
          anchorEl={anchorEl}
          open={popoverOpen}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}>
          <CommonTextField
            fullWidth
            placeholder="Filter on email, amount.."
            variant="outlined"
            onChange={searchData}
          />
        </Popover>
      </TableCell>
    </TableRow>
  );

  const renderRows = ({
    amount,
    id,
    status,
    supplier,
    receivable,
    customer,
  }: RTPWithDisplaying) => {
    const { dateIssued, dueDate, dateTimeIssued } = receivable;
    const { email } = supplier.primaryContact;
    const { amount: rtpAmount, currency } = amount;
    const { name: payer } = customer;
    const formattedDateTimeIssued = format(new Date(dateTimeIssued), "HH:mm");
    const searchTerms = {
      username: supplier.primaryContact.email,
      orgname: supplier.name,
      phone: supplier.primaryContact.telephone,
    };

    return (
      <Fragment key={id}>
        <TableRow
          onClick={() => setExpanded(expanded.includes(id) ? [] : [id])}
          className={classes.tableRow}>
          <TableCell padding="checkbox">
            <IconButton aria-label="expand row" size="small">
              {expanded.includes(id) ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
            </IconButton>
          </TableCell>
          <TableCell>{email}</TableCell>
          <TableCell>{dateIssued}</TableCell>
          <TableCell>{formattedDateTimeIssued}</TableCell>
          <TableCell>{dueDate}</TableCell>
          <TableCell align="right">{formatCurrency(rtpAmount, currency)}</TableCell>
          <TableCell>{RTP_STATUS_LABEL[status]}</TableCell>
          <TableCell padding="checkbox" />
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Collapse in={expanded.includes(id)} timeout="auto" unmountOnExit>
              <DetailPanel
                email={email}
                payer={payer}
                onNavigateToUser={() => handleNavigateToUserOrUpdateUser(searchTerms)}
              />
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  };

  const renderEmptyDataRow = () => (
    <TableRow>
      <TableCell colSpan={8} align="center">
        No Matching RTPS found.
      </TableCell>
    </TableRow>
  );

  return (
    <Box my={4}>
      <ScreenHeader title="RTP's" />
      <Card elevation={12}>
        <Table
          data={data.filter((rtp) => !rtp.hidden)}
          renderHeader={renderHeader}
          renderRows={renderRows}
          showPagination={true}
          onEntriesChange={handleOnEntriesChange}
          onPageChange={setPage}
          entries={entries}
          page={page}
          lastPage={lastPage}
          renderEmptyDataRow={renderEmptyDataRow}
        />
      </Card>
    </Box>
  );
};

export default RTPDashboardList;
