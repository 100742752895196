import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { formatISO } from "date-fns";
import { useFormik } from "formik";

import { REPORT_FILTERS } from "@APP/constants";
import { useAlert, useHandleErrorCodes } from "@APP/hooks";
import {
  hideLoader,
  setReportData,
  setReportFilter,
  setReportFrom,
  setReportTo,
  showLoader,
} from "@APP/redux";
import { API } from "@APP/services";
import { RtpReportData } from "@APP/types";
import {
  appropriateGrouping,
  formatErrorMessage,
  generateInitialDatesFromReport,
  stripDuplicates,
} from "@APP/utils";

import { ChartComponent } from "./ChartContainer";

type Props = {
  components: ChartComponent[];
  visualisations: string[];
  setVisualisations: (visualisations: string[]) => void;
};

const useStyles = makeStyles((theme) => ({
  box: {
    minHeight: 260,
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    display: "flex",
  },
}));

const FilterContainer = ({ components, setVisualisations, visualisations }: Props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const alert = useAlert();
  const handleErrorCodes = useHandleErrorCodes();

  const handleOnSubmit = async (values: { [key: string]: string[] }) => {
    const selectedValues = [...values.visualisations];
    const returnedVisualisations: string[] = [];

    selectedValues.forEach(async (value) => {
      const selectedComponent = components.find((component) => component.reportName === value);
      if (selectedComponent) {
        const { reportName, report }: ChartComponent = selectedComponent;
        const { from, to } = report;
        const filter = {
          from: formatISO(new Date(from)),
          to: formatISO(new Date(to)),
          group: appropriateGrouping(from, to),
        };
        if (selectedComponent.report.data.length) {
          returnedVisualisations.push(reportName);

          return;
        }
        if (!selectedComponent.report.data.length) {
          try {
            dispatch(showLoader());
            const report = await API.getReport(reportName, filter);
            const datesFromReport = generateInitialDatesFromReport(reportName, report.data);

            dispatch(setReportData({ reportName, data: report.data }));
            dispatch(setReportFrom(reportName, from));
            dispatch(setReportTo(reportName, to));

            const filteredData = [...(report.data as RtpReportData[])];

            selectedComponent.chartFilters?.forEach((selectedFilter) => {
              if (selectedComponent.report.filters[REPORT_FILTERS[selectedFilter]]) {
                const selectedValues = stripDuplicates(
                  filteredData.map(({ [selectedFilter]: value }) => value as string),
                );

                dispatch(
                  setReportFilter({
                    reportName,
                    filterName: REPORT_FILTERS[selectedFilter],
                    selectedValues,
                  }),
                );
              }
            });

            dispatch(
              setReportFilter({
                reportName,
                filterName: REPORT_FILTERS.dates,
                selectedValues: datesFromReport,
              }),
            );
            if (report) {
              returnedVisualisations.push(value);
            }
          } catch (error: any) {
            const errorData = error?.response?.data;
            const isHandled = handleErrorCodes(errorData?.errorCode);

            if (!isHandled) return;
            alert(`Error fetching report: ${selectedComponent.label}`, formatErrorMessage(error));
          } finally {
            dispatch(hideLoader());
          }
        }
      }
    });
    dispatch(setVisualisations(returnedVisualisations));
  };

  const { handleSubmit, handleChange, values } = useFormik({
    initialValues: {
      visualisations: visualisations,
    },
    onSubmit: handleOnSubmit,
  });

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <CardContent>
          <Box className={classes.box}>
            <FormGroup>
              {components.map(({ label, reportName }, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      name="visualisations"
                      onChange={handleChange}
                      value={reportName}
                      checked={values.visualisations.includes(reportName)}
                    />
                  }
                  label={label}
                />
              ))}
            </FormGroup>
          </Box>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            View
          </Button>
        </CardContent>
      </Card>
    </form>
  );
};

export default FilterContainer;
