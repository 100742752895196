import CONFIG from "@APP/config";
import { REPORT_NAMES } from "@APP/constants";
import {
  ActivityReportRequestFilter,
  AdminRTPFilter,
  RTP,
  ReportDataType,
  RequestPagination,
  ResponsePagination,
  UserSearchResponse,
  UserSearchTerms,
} from "@APP/types";

import { REPORT_ENDPOINTS } from "../constants";
import request from "../request";

export const getSearchedUsers = async (
  searchTerms: UserSearchTerms,
  pagination?: RequestPagination,
) => {
  const { page = 0, entries = 10 } = pagination!;
  const response = await request<{
    data: UserSearchResponse[];
    links: ResponsePagination;
    meta: { totalItems: number };
  }>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/user-mgmt/admin/users/search`,
    params: {
      page: `${page * entries};;;${entries}`,
      ...searchTerms,
    },
  });

  return response.data;
};

export const changeUserBlockStatus = async (username: string, blocked: boolean) => {
  const response = await request({
    method: blocked ? "POST" : "DELETE",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/user-mgmt/admin/users/blocked`,
    data: { username: username },
  });

  return response;
};

// this endpoint isn't yet active.
export const getRTPSinDateRange = async (
  filter: AdminRTPFilter,
  pagination?: RequestPagination,
) => {
  const { page = 0, entries = 25 } = pagination!;
  const response = await request<{ data: RTP[]; links: ResponsePagination }>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/admin/request-to-pay/payment-requests`,
    params: {
      page: `${page ? page * entries + 1 : page * entries};;;${entries}`,
      ...filter,
    },
  });

  return response.data;
};

export const getRTPSofUser = async (customerId: string) => {
  const response = await request<{ data: RTP[] }>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/admin/request-to-pay/payment-requests/${customerId}`,
    params: {
      customerId,
    },
  });

  return response.data;
};

export const getReport = async (
  reportName: keyof typeof REPORT_NAMES,
  filter: ActivityReportRequestFilter,
  pagination?: RequestPagination,
) => {
  const { page, entries } = { page: 0, entries: 50, ...pagination };

  const response = await request<{ data: ReportDataType[]; links: ResponsePagination }>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/whd/${
      REPORT_ENDPOINTS[REPORT_NAMES[reportName]]
    }`,
    params: {
      page: `${page * entries};;;${entries}`,
      ...filter,
    },
  });

  return response.data;
};

export default {
  getSearchedUsers,
  changeUserBlockStatus,
  getRTPSinDateRange,
  getRTPSofUser,
  getReport,
};
