import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";

import { SORT_TYPES } from "@APP/constants";
import { SortType } from "@APP/types";

/**
 * Returns correct sort icon based on current state of sortBy and sortType
 */

interface Props {
  active: boolean;
  type: SortType;
}

const SortIcon = ({ active, type }: Props) => {
  if (active) {
    switch (type) {
      case SORT_TYPES.asc:
        return <ExpandMoreIcon color="error" />;
      case SORT_TYPES.desc:
        return <ExpandLessIcon color="error" />;
    }
  }

  return <UnfoldMoreIcon color="error" />;
};

export default SortIcon;
