import {
  Bar,
  BarChart as RechartsBarChart,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

import { ChartData } from "@APP/types";

import { ChartProps } from "./ChartContainer";
import { ChartLegend, ChartTooltip } from "./components";

const BarChart = ({ data, percent }: ChartProps) => {
  const dataAsChartData = [...(data as ChartData[])];

  return (
    <ResponsiveContainer height="100%" width="100%">
      <RechartsBarChart data={dataAsChartData} layout="horizontal">
        <Tooltip content={<ChartTooltip percent={percent} />} />
        <Legend
          layout="vertical"
          align="right"
          verticalAlign="middle"
          iconSize={15}
          iconType="rect"
          payload={dataAsChartData}
          content={<ChartLegend percent={percent} data={dataAsChartData} />}
        />
        <Bar dataKey="value" barSize={20}>
          {dataAsChartData.map((entry: ChartData, index: number) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Bar>
      </RechartsBarChart>
    </ResponsiveContainer>
  );
};

export default BarChart;
