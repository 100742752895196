import { formatISO, startOfDay, startOfWeek, startOfYesterday, sub } from "date-fns";
import { createReducer } from "deox";

import { Partners, REPORT_TRANSACTION_STATUSES } from "@APP/constants";
import {
  ChannelsReportData,
  DeclinedReportData,
  Partner,
  PeakTrafficReportData,
  RTP,
  RTPDeliveryChannel,
  ReportTransactionStatus,
  TransactionsReportData,
  UserSearchResponse,
  UsersActiveReportData,
  UsersDectiveReportData,
  UsersRegisteredByBankData,
  UsersRegisteredByErpData,
  REPORT_ERP,
} from "@APP/types";
import { formatReportPayload } from "@APP/utils";

import {
  resetSearchResults,
  setActivityVisualisations,
  setRTPS,
  setReportData,
  setReportFilter,
  setReportFrom,
  setReportTo,
  setRtpIssuedFrom,
  setRtpIssuedTo,
  setSelectedDates,
  setSelectedPartners,
  setSelectedUser,
  setUserSearchResults,
  setUsersRTPS,
  setUsersVisualisations,
} from "../actions";

export interface AdminState {
  users: {
    results: UserSearchResponse[];
    selectedUser: UserSearchResponse | null;
    usersRTPS: RTP[];
  };
  rtpDashboard: {
    data: RTP[];
    issuedFrom: string;
    issuedTo: string;
  };
  reports: {
    declined: {
      data: DeclinedReportData[];
      from: string;
      to: string;
      filters: {
        selectedDates: string[];
        selectedPartners: Partner[];
      };
    };
    transactions: {
      data: TransactionsReportData[];
      from: string;
      to: string;
      filters: {
        selectedDates: string[];
        selectedPartners: Partner[];
        selectedStatus: ReportTransactionStatus[];
      };
    };
    channels: {
      data: ChannelsReportData[];
      from: string;
      to: string;
      filters: {
        selectedDates: string[];
        selectedPartners: Partner[];
        selectedStatus: ReportTransactionStatus[];
        selectedChannels: RTPDeliveryChannel[];
      };
    };
    peakTraffic: {
      data: PeakTrafficReportData[];
      from: string;
      to: string;
      filters: {
        selectedDates: string[];
        selectedPartners: Partner[];
      };
    };
    usersActive: {
      data: UsersActiveReportData[];
      from: string;
      to: string;
      filters: {
        selectedDates: string[];
        selectedPartners: Partner[];
      };
    };
    usersDeactive: {
      data: UsersDectiveReportData[];
      from: string;
      to: string;
      filters: {
        selectedDates: string[];
        selectedPartners: Partner[];
      };
    };
    usersRegisteredByBank: {
      data: UsersRegisteredByBankData[];
      from: string;
      to: string;
      filters: {
        selectedDates: string[];
        selectedPartners: Partner[];
        selectedBanks: string[];
      };
    };
    usersRegisteredByErp: {
      data: UsersRegisteredByErpData[];
      from: string;
      to: string;
      filters: {
        selectedDates: string[];
        selectedPartners: Partner[];
        selectedErps: REPORT_ERP[];
      };
    };
  };
  activityVisualisations: string[];
  usersVisualisations: string[];
}

const initialTo = startOfDay(new Date());
const weekStart = startOfWeek(new Date());

const defaultFrom =
  initialTo === weekStart ? formatISO(sub(weekStart, { days: 7 })) : formatISO(weekStart);
const defaultTo = formatISO(new Date(initialTo));

const initialPartners = Object.values(Partners);
const initialStatuses = Object.values(REPORT_TRANSACTION_STATUSES);
const initialChannels = Object.values(RTPDeliveryChannel);

const defaultState: AdminState = {
  users: {
    results: [],
    selectedUser: null,
    usersRTPS: [],
  },
  rtpDashboard: {
    data: [] as RTP[],
    issuedFrom: formatISO(sub(new Date(), { weeks: 1 })),
    issuedTo: formatISO(startOfYesterday()),
  },
  reports: {
    declined: {
      data: [],
      from: formatISO(sub(weekStart, { days: 30 })),
      to: defaultTo,
      filters: {
        selectedDates: [],
        selectedPartners: initialPartners,
      },
    },
    transactions: {
      data: [],
      from: defaultFrom,
      to: defaultTo,
      filters: {
        selectedDates: [],
        selectedPartners: initialPartners,
        selectedStatus: initialStatuses,
      },
    },
    channels: {
      data: [],
      from: defaultFrom,
      to: defaultTo,
      filters: {
        selectedDates: [],
        selectedPartners: initialPartners,
        selectedStatus: initialStatuses,
        selectedChannels: initialChannels,
      },
    },
    peakTraffic: {
      data: [],
      from: defaultFrom,
      to: defaultTo,
      filters: {
        selectedDates: [],
        selectedPartners: initialPartners,
      },
    },

    usersDeactive: {
      data: [],
      from: defaultFrom,
      to: defaultTo,
      filters: {
        selectedDates: [],
        selectedPartners: initialPartners,
      },
    },
    usersActive: {
      data: [],
      from: defaultFrom,
      to: defaultTo,
      filters: {
        selectedDates: [],
        selectedPartners: initialPartners,
      },
    },
    usersRegisteredByBank: {
      data: [],
      from: defaultFrom,
      to: defaultTo,
      filters: {
        selectedDates: [],
        selectedPartners: initialPartners,
        selectedBanks: [],
      },
    },
    usersRegisteredByErp: {
      data: [],
      from: defaultFrom,
      to: defaultTo,
      filters: {
        selectedDates: [],
        selectedPartners: initialPartners,
        selectedErps: [],
      },
    },
  },
  activityVisualisations: [],
  usersVisualisations: [],
};

const adminReducer = createReducer(defaultState, (handleAction) => [
  handleAction(setUserSearchResults, (state, { payload }) => ({
    ...state,
    users: { ...state.users, results: payload },
  })),
  handleAction(setSelectedUser, (state, { payload }) => ({
    ...state,
    users: { ...state.users, selectedUser: payload },
  })),
  handleAction(setUsersRTPS, (state, { payload }) => ({
    ...state,
    users: { ...state.users, usersRTPS: payload },
  })),
  handleAction(resetSearchResults, (state) => ({
    ...state,
    users: defaultState.users,
  })),
  handleAction(setRtpIssuedFrom, (state, { payload }) => ({
    ...state,
    rtpDashboard: { ...state.rtpDashboard, issuedFrom: payload },
  })),
  handleAction(setRtpIssuedTo, (state, { payload }) => ({
    ...state,
    rtpDashboard: { ...state.rtpDashboard, issuedTo: payload },
  })),
  handleAction(setRTPS, (state, { payload }) => ({
    ...state,
    rtpDashboard: { ...state.rtpDashboard, data: payload },
  })),
  handleAction(setActivityVisualisations, (state, { payload }) => ({
    ...state,
    activityVisualisations: payload,
  })),
  handleAction(setUsersVisualisations, (state, { payload }) => ({
    ...state,
    usersVisualisations: payload,
  })),
  handleAction(setReportData, (state, { payload }) => ({
    ...state,
    reports: {
      ...state.reports,
      [payload.reportName]: {
        ...state.reports[payload.reportName],
        data: formatReportPayload(payload.reportName, payload.data),
      },
    },
  })),
  handleAction(setSelectedDates, (state, { payload }) => ({
    ...state,
    reports: {
      ...state.reports,
      [payload.reportName]: {
        ...state.reports[payload.reportName],
        filters: {
          ...state.reports[payload.reportName].filters,
          selectedDates: payload.selectedDates,
        },
      },
    },
  })),
  handleAction(setSelectedPartners, (state, { payload }) => ({
    ...state,
    reports: {
      ...state.reports,
      [payload.reportName]: {
        ...state.reports[payload.reportName],
        filters: {
          ...state.reports[payload.reportName].filters,
          selectedPartners: payload.selectedPartners,
        },
      },
    },
  })),

  handleAction(setReportFrom, (state, { payload }) => ({
    ...state,
    reports: {
      ...state.reports,
      [payload.reportName]: {
        ...state.reports[payload.reportName],
        from: payload.from,
      },
    },
  })),
  handleAction(setReportTo, (state, { payload }) => ({
    ...state,
    reports: {
      ...state.reports,
      [payload.reportName]: {
        ...state.reports[payload.reportName],
        to: payload.to,
      },
    },
  })),

  handleAction(setReportFilter, (state, { payload }) => ({
    ...state,
    reports: {
      ...state.reports,
      [payload.reportName]: {
        ...state.reports[payload.reportName],
        filters: {
          ...state.reports[payload.reportName].filters,
          [payload.filterName]: payload.selectedValues,
        },
      },
    },
  })),
]);

export default adminReducer;
