import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

const DetailPanel = ({
  payer,
  email,
  onNavigateToUser,
}: {
  payer: string;
  email?: string;
  onNavigateToUser: () => Promise<void>;
}) => (
  <Box margin={1}>
    <Table size="small" aria-label="additional rtp details">
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox" />
          <TableCell>Payer</TableCell>
          <TableCell>Go to</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell padding="checkbox" />
          <TableCell>{payer}</TableCell>
          <TableCell>
            <Button variant="text" onClick={onNavigateToUser}>
              {email}
            </Button>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </Box>
);

export default DetailPanel;
