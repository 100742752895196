import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format, isAfter } from "date-fns";
import { Box, Button, Card, Divider, Grid, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useFormik } from "formik";
import { get, orderBy } from "lodash";
import queryString from "query-string";
import * as Yup from "yup";

import { CommonDateRangeSelectionButtons, Page } from "@APP/components";
import {
  NUMBER_OF_FIRST_PAGE_IN_TABLE_WITH_PAGINATION,
  SORT_TYPES,
  commonFormatDate,
} from "@APP/constants";
import { useAlert, useHandleErrorCodes } from "@APP/hooks";
import {
  AppState,
  hideLoader,
  setRTPS,
  setRtpIssuedFrom,
  setRtpIssuedTo,
  showLoader,
} from "@APP/redux";
import { API } from "@APP/services";
import { AdminRTPFilter, RTP, RtpSortBy, SortType } from "@APP/types";
import { formatErrorMessage } from "@APP/utils";

import RTPDashboardList from "./RTPDashboardList";

export type RTPWithDisplaying = RTP & { hidden: boolean };

enum RtpSortPaths {
  dateIssued = "receivable.dateIssued",
  dueDate = "receivable.dueDate",
  amount = "amount.amount",
  status = "status",
  email = "supplier.primaryContact.email",
  timeIssued = "receivable.dateTimeIssued",
}

export const adminRTPValidationSchema = Yup.object().shape({
  from: Yup.date().required("Start date is required"),
  to: Yup.date().test("to", "End date must be after start date", (value, context) => {
    if (!value) return false;
    // start date can be the same as end date
    if (value.getTime() === context.parent.from.getTime()) return true;
    if (!context.parent.from) return false;

    return isAfter(value, context.parent.from);
  }),
});

const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    marginTop: theme.spacing(0.5),
  },
}));

const RTPDashboardView = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const classes = useStyles();
  const handleErrorCodes = useHandleErrorCodes();
  const [startDatePickerOpen, setStartDatePickerOpen] = useState(false);
  const [endDatePickerOpen, setEndDatePickerOpen] = useState(false);
  const [entries, setEntries] = useState(25);
  const [page, setPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [rtpSort, setRtpSort] = useState<RtpSortBy>(RtpSortBy.dateIssued);
  const [sortedRtps, setSortedRtps] = useState<RTPWithDisplaying[]>([]);
  const [sortType, setSortType] = useState<SortType>(SORT_TYPES.asc);
  const { data, issuedFrom, issuedTo } = useSelector(
    ({
      userAdmin: {
        rtpDashboard: { issuedFrom, issuedTo, data },
      },
    }: AppState) => ({
      issuedFrom,
      issuedTo,
      data,
    }),
  );

  const appendHiddenToRtp = (rtps: RTP[]): RTPWithDisplaying[] =>
    rtps.map((rtp) => ({ ...rtp, hidden: false }));

  useEffect(() => {
    !!data.length && setSortedRtps(appendHiddenToRtp(data));
  }, []);

  const getRtpsInDateRangeRecursively = async (
    filter: AdminRTPFilter,
    page = 0,
    prevRtps: RTP[] = [],
  ): Promise<RTP[]> => {
    const { data: rtpData, links } = await API.getRTPSinDateRange(filter, { page });
    const rtps = [...prevRtps, ...rtpData] as RTP[];

    if (links.next) {
      return getRtpsInDateRangeRecursively(filter, page + 1, rtps);
    }
    const { query } = queryString.parseUrl(links?.last ?? "");

    if (query.page) {
      const [lastPageEntries] = (query.page as string).split(";;;");
      const lastPageIndex = Number(lastPageEntries) / entries;
      setLastPage(Math.floor(lastPageIndex));
    }

    return rtps;
  };

  const fetchRTPs = async ({ from, to }: { from: string; to: string }) => {
    try {
      dispatch(showLoader());
      const rtpsData = await getRtpsInDateRangeRecursively({
        issuedFrom: from,
        issuedTo: to,
      });

      dispatch(setRTPS(rtpsData));
      setSortedRtps(appendHiddenToRtp(rtpsData));
    } catch (error: any) {
      const errorData = error?.response?.data;
      const isHandled = handleErrorCodes(errorData?.errorCode);

      if (!isHandled) return;
      alert("Error", formatErrorMessage(error));
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleSorting = (by: RtpSortBy) => {
    setSortType(sortType === SORT_TYPES.asc ? SORT_TYPES.desc : SORT_TYPES.asc);

    if (by === RtpSortBy.amount)
      setSortedRtps(orderBy(sortedRtps, (rtp) => parseFloat(get(rtp, RtpSortPaths[by])), sortType));
    else if (by === RtpSortBy.timeIssued)
      setSortedRtps(
        orderBy(
          sortedRtps,
          (rtp) => format(new Date(get(rtp, RtpSortPaths[by])), "HH:mm"),
          sortType,
        ),
      );
    else {
      setSortedRtps(orderBy(sortedRtps, RtpSortPaths[by], sortType));
    }
    setRtpSort(by);
  };

  const searchData = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value.toLowerCase();
      if (!value) {
        setSortedRtps(sortedRtps.map((rtp) => ({ ...rtp, hidden: false })));

        return;
      }

      const filteredRtps = sortedRtps.map((rtp) => {
        const {
          amount: { amount },
          receivable: { dateIssued, dueDate },
          status,
          supplier: {
            primaryContact: { email },
          },
        } = rtp;

        const filterOn = [dateIssued, dueDate, amount, status, email];
        if (filterOn.some((filter) => filter?.toLowerCase().includes(value))) {
          return { ...rtp, hidden: false };
        }

        return { ...rtp, hidden: true };
      });

      setSortedRtps(filteredRtps);
    },
    [sortedRtps],
  );

  const handleOnEntriesChange = (entries: number) => {
    setPage(NUMBER_OF_FIRST_PAGE_IN_TABLE_WITH_PAGINATION);
    setEntries(entries);
    setLastPage(Math.ceil(sortedRtps.length / entries));
  };

  const clearSearchFilter = () =>
    setSortedRtps(sortedRtps.map((rtp) => ({ ...rtp, hidden: false })));

  const handleOnSubmit = ({ from, to }: { from: string; to: string }) => {
    dispatch(setRtpIssuedFrom(from));
    dispatch(setRtpIssuedTo(to));
    fetchRTPs({ from, to });
    setSubmitting(false);
  };

  const { errors, handleSubmit, isSubmitting, touched, values, setFieldValue, setSubmitting } =
    useFormik({
      initialValues: {
        from: issuedFrom,
        to: issuedTo,
      },
      validationSchema: adminRTPValidationSchema,
      onSubmit: handleOnSubmit,
    });

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Page title="RTP Dashboard">
        <form onSubmit={handleSubmit}>
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card elevation={4}>
                  <Box p={1}>
                    <Grid container item spacing={1}>
                      <Grid className={classes.buttonsContainer} item xs={12} md={8} lg={8}>
                        <CommonDateRangeSelectionButtons
                          fieldValueSetter={setFieldValue}
                          contentJustify="space-evenly"
                          values={values}
                          handleOnSubmit={handleOnSubmit}
                        />
                      </Grid>
                      <Grid container item direction="column" xs={12} sm={4} md={4} spacing={1}>
                        <Grid item>
                          <DatePicker
                            renderInput={(props) => (
                              <TextField
                                error={Boolean(touched.from && errors.from)}
                                helperText={touched.from && errors.from}
                                fullWidth
                                size="small"
                                onClick={() => setStartDatePickerOpen(true)}
                                {...props}
                              />
                            )}
                            onChange={(value) => {
                              setFieldValue("from", value);
                            }}
                            value={values.from}
                            label="Start Date"
                            inputFormat={commonFormatDate}
                            disableFuture
                            open={startDatePickerOpen}
                            onOpen={() => setStartDatePickerOpen(true)}
                            onClose={() => setStartDatePickerOpen(false)}
                          />
                        </Grid>
                        <Grid item>
                          <DatePicker
                            renderInput={(props) => (
                              <TextField
                                error={Boolean(touched.to && errors.to)}
                                helperText={touched.to && errors.to}
                                fullWidth
                                size="small"
                                onClick={() => setEndDatePickerOpen(true)}
                                label="End Date"
                                {...props}
                              />
                            )}
                            onChange={(value) => {
                              setFieldValue("to", value);
                            }}
                            value={values.to}
                            label="End Date"
                            inputFormat={commonFormatDate}
                            disableFuture
                            open={endDatePickerOpen}
                            onOpen={() => setEndDatePickerOpen(true)}
                            onClose={() => setEndDatePickerOpen(false)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Box p={2}>
                    <Grid container item>
                      <Grid item lg={4} xs={false} />
                      <Grid item lg={4} xs={12}>
                        <Button
                          color="primary"
                          disabled={isSubmitting}
                          fullWidth
                          type="button"
                          onClick={() => {
                            handleOnSubmit(values);
                          }}
                          variant="contained">
                          View
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Card>
              </Grid>
            </Grid>
            <RTPDashboardList
              data={sortedRtps.slice(page * entries, (page + 1) * entries)}
              rtpSort={rtpSort}
              sortType={sortType}
              entries={entries}
              page={page}
              lastPage={lastPage}
              setEntries={setEntries}
              setPage={setPage}
              clearSearchFilter={clearSearchFilter}
              searchData={searchData}
              handleSorting={handleSorting}
              handleOnEntriesChange={handleOnEntriesChange}
            />
          </Box>
        </form>
      </Page>
    </LocalizationProvider>
  );
};

export default RTPDashboardView;
