// import { Box, Button, Grid, GridJustification } from "@mui/material";
import { Box, Button, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  endOfMonth,
  endOfWeek,
  formatISO,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYesterday,
  sub,
} from "date-fns";

import { DateRangeButton } from "@APP/types";

type SetFieldValueType = (field: string, value: string) => void;

type Props = {
  fieldValueSetter: SetFieldValueType;
  handleOnSubmit: (values: any) => void;
  values: any;
};

type CommonDateRangeSelectionButtonProps = DateRangeButton & Props;

type CommonDateRangeSelectionButtonsProps = Props & {
  // contentJustify: GridJustification;
  contentJustify: any;
};

const useStyles = makeStyles((theme) => ({
  buttonBox: {
    spacing: theme.spacing(1),
  },
  buttonGridContainer: {
    spacing: theme.spacing(2),
  },
}));

export const commonDateRanges: DateRangeButton[] = [
  {
    label: "Today",
    from: formatISO(startOfDay(new Date())),
    to: formatISO(new Date()),
  },
  {
    label: "This week",
    from: formatISO(startOfWeek(new Date())),
    to: formatISO(new Date()),
  },
  {
    label: "This Month",
    from: formatISO(startOfMonth(new Date())),
    to: formatISO(new Date()),
  },
];

export const secondaryDateRanges: DateRangeButton[] = [
  {
    label: "Yesterday",
    from: formatISO(startOfYesterday()),
    to: formatISO(startOfDay(new Date())),
  },
  {
    label: "Last week",
    from: formatISO(sub(startOfWeek(new Date()), { weeks: 1 })),
    to: formatISO(sub(endOfWeek(new Date()), { weeks: 1 })),
  },
  {
    label: "Last Month",
    from: formatISO(sub(startOfMonth(new Date()), { months: 1 })),
    to: formatISO(sub(endOfMonth(new Date()), { months: 1 })),
  },
];

const CommonDateRangeSelectionButton = ({
  label,
  from,
  to,
  fieldValueSetter,
  handleOnSubmit,
  values,
}: CommonDateRangeSelectionButtonProps) => (
  <Grid item xs={4}>
    <Button
      key={label}
      variant="outlined"
      fullWidth={true}
      type="submit"
      onClick={() => {
        fieldValueSetter("from", from);
        fieldValueSetter("to", to);
        handleOnSubmit({ ...values, from, to });
      }}>
      {label}
    </Button>
  </Grid>
);

const CommonDateRangeSelectionButtons = ({
  fieldValueSetter,
  contentJustify,
  handleOnSubmit,
  values,
}: CommonDateRangeSelectionButtonsProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.buttonBox}>
      <Grid container spacing={1}>
        <Grid
          container
          item
          className={classes.buttonGridContainer}
          spacing={1}
          // justifyContent="space-between">
          justifyContent={contentJustify}>
          {commonDateRanges.map(({ label, from, to }: DateRangeButton) => (
            <CommonDateRangeSelectionButton
              key={label}
              label={label}
              from={from}
              to={to}
              values={values}
              fieldValueSetter={fieldValueSetter}
              handleOnSubmit={handleOnSubmit}
            />
          ))}
        </Grid>
        <Grid
          container
          item
          className={classes.buttonGridContainer}
          spacing={1}
          justifyContent={contentJustify}>
          {secondaryDateRanges.map(({ label, from, to }: DateRangeButton) => (
            <CommonDateRangeSelectionButton
              key={label}
              label={label}
              from={from}
              to={to}
              values={values}
              fieldValueSetter={fieldValueSetter}
              handleOnSubmit={handleOnSubmit}
            />
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default CommonDateRangeSelectionButtons;
