import { useContext } from "react";

import { AlertContext, AlertProvider } from "./AlertProvider";

const useAlert = () => {
  const { openAlert } = useContext(AlertContext);

  return openAlert;
};

export { AlertProvider };
export default useAlert;
