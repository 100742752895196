/**
 * Absoulte paths for main app screens.
 */
export enum SCREEN_PATHS {
  ROOT = "/app",
  APP_ERROR = "/app/user-error",
  DASHBOARD = "/app/dashboard",
  REQUESTS_TO_PAY = "/app/requests-to-pay",
  REQUEST_TO_PAY_DETAILS = "/app/requests-to-pay/:requestId",
  SETTINGS = "/app/settings",
  SETUP_ACCOUNTING_PACKAGE = "/app/setup/accounting-package",
  LOGIN = "/login",
  LOGOUT = "/logout",
  NOT_FOUND = "/404",
  UPDATE_USER = "/app/update-user",
  UPDATE_USER_DETAILS = "/app/update-user/:userId",
  UPDATE_USER_SUCCESS = "/app/update-user/success",
  ACTIVITY_DASHBOARD = "/app/activity-dashboard",
  USER_DASHBOARD = "/app/user-dashboard",
  RTP_DASHBOARD = "/app/rtp-dashboard",
  RTP_DASHBOARD_RANGE = "/app/rtp-dashboard/:startDate/:endDate",
}

/**
 * Relative paths for modal views.
 */
export enum MODAL_PATHS {
  PLACEHOLDER = "/modals/placeholder",
}
// leaving this empty incase we need to add more modal paths

const PATHS = {
  SCREEN_PATHS,
  MODAL_PATHS,
};

export default PATHS;
