import { useSelector } from "react-redux";
import { Grid } from "@mui/material";

import {
  BarChart,
  ChannelsHeaderButton,
  ChartContainer,
  DeclinedHeaderButton,
  FilterContainer,
  LineChart,
  Page,
  PeakTrafficHeaderButton,
  PieChart,
  TransactionsHeaderButton,
} from "@APP/components";
import { ChartComponent } from "@APP/components/charts/ChartContainer";
import { CHART_FILTER_FIELDS, REPORT_NAMES } from "@APP/constants";
import { AppState, setActivityVisualisations } from "@APP/redux";
import { reportDataFormatter } from "@APP/utils";

export const ActivityDashboardView = () => {
  const { activityVisualisations, peakTraffic, channels, transactions, declined } = useSelector(
    ({
      userAdmin: {
        activityVisualisations,
        reports: { peakTraffic, channels, declined, transactions },
      },
    }: AppState) => ({
      activityVisualisations,
      peakTraffic,
      channels,
      transactions,
      declined,
    }),
  );

  const activityCharts: ChartComponent[] = [
    {
      reportName: REPORT_NAMES.channels,
      label: "Transactions by Comms Channel",
      component: BarChart,
      report: channels,
      headerButton: ChannelsHeaderButton,
      chartFilters: [
        CHART_FILTER_FIELDS.partner,
        CHART_FILTER_FIELDS.status,
        CHART_FILTER_FIELDS.channel,
      ],
    },
    {
      reportName: REPORT_NAMES.transactions,
      label: "Transactions by Partner",
      component: PieChart,
      report: transactions,
      headerButton: TransactionsHeaderButton,
      chartFilters: [CHART_FILTER_FIELDS.partner, CHART_FILTER_FIELDS.status],
    },
    {
      reportName: REPORT_NAMES.declined,
      label: "Declined RTP insights",
      component: PieChart,
      report: declined,
      headerButton: DeclinedHeaderButton,
      chartFilters: [CHART_FILTER_FIELDS.partner],
    },
    {
      reportName: REPORT_NAMES.peakTraffic,
      label: "Peak traffic periods",
      component: LineChart,
      headerButton: PeakTrafficHeaderButton,
      size: 12,
      tooltipProps: {
        valueTitle: "Request to pay",
      },
      report: peakTraffic,
      lineChart: true,
      chartFilters: [CHART_FILTER_FIELDS.partner],
    },
  ];

  return (
    <Page title="Activity Dashboard">
      <Grid container spacing={3} justifyContent="flex-start">
        <Grid item xs={12} md={6}>
          <FilterContainer
            components={activityCharts}
            visualisations={activityVisualisations}
            setVisualisations={setActivityVisualisations}
          />
        </Grid>
        <ChartContainer
          components={activityCharts}
          visualisations={activityVisualisations}
          dataFormatter={reportDataFormatter}
        />
      </Grid>
    </Page>
  );
};

export default ActivityDashboardView;
