import { ComponentType, ElementType, useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import DashboardIcon from "@mui/icons-material/Dashboard";
import InputIcon from "@mui/icons-material/Input";
import ChartIcon from "@mui/icons-material/PieChart";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import clsx from "clsx";

import { IMAGES } from "@APP/assets";
import { SCREEN_PATHS } from "@APP/navigation";
import { CLOSE_SIDEBAR_WIDTH, OPEN_SIDEBAR_WIDTH, TOP_BAR_HEIGHT } from "@APP/styles";

import NavItem from "./NavItem";

interface Props {
  openMobile?: boolean;
  onMobileClose?: () => void;
  isFullSideBar: boolean;
}

interface itemMenu {
  href: SCREEN_PATHS;
  icon?: ElementType | ComponentType;
  title: string;
}

const items: itemMenu[] = [
  {
    href: SCREEN_PATHS.ACTIVITY_DASHBOARD,
    icon: ChartIcon,
    title: "Activity Dashboard",
  },
  {
    href: SCREEN_PATHS.USER_DASHBOARD,
    icon: DashboardIcon,
    title: "User Dashboard",
  },
  {
    href: SCREEN_PATHS.RTP_DASHBOARD,
    icon: AccountBalanceWalletIcon,
    title: "RTP Dashboard",
  },
  {
    href: SCREEN_PATHS.UPDATE_USER,
    icon: RecentActorsIcon,
    title: "Update User",
  },
];

const useStyles = makeStyles((theme) => ({
  drawerBase: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
  },
  openedDrawerBase: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: OPEN_SIDEBAR_WIDTH,
    },
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  closedDrawerBase: {
    [theme.breakpoints.down("sm")]: {
      width: 0,
    },
    [theme.breakpoints.up("sm")]: {
      width: CLOSE_SIDEBAR_WIDTH,
    },
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  logoLink: {
    color: "inherit",
    fontSize: 18,
  },
  logoContainer: {
    height: TOP_BAR_HEIGHT,
    width: OPEN_SIDEBAR_WIDTH,
    background: theme.palette.background.gradient ?? theme.palette.primary.main,
    color: theme.palette.primary.main,
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      borderTopRightRadius: theme.shape.borderRadius,
    },
  },
  logo: {
    height: 42,
  },
  divider: {
    height: 1,
    backgroundColor: theme.palette.common.white,
  },
  sidebarTitle: {
    letterSpacing: 1,
    padding: theme.spacing(1),
    color: theme.palette.common.white,
  },
}));

const SideBar = ({ onMobileClose, openMobile = false, isFullSideBar }: Props) => {
  const theme = useTheme();
  const classes = useStyles();
  const location = useLocation();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (onMobileClose && isFullSideBar && isPhone) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      className={clsx(classes.drawerBase, {
        [classes.openedDrawerBase]: isFullSideBar,
        [classes.closedDrawerBase]: !isFullSideBar,
      })}>
      <RouterLink className={classes.logoLink} to="/">
        <Box display="flex" alignItems="center" flexDirection="row" height={TOP_BAR_HEIGHT}>
          <Box
            className={classes.logoContainer}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="row">
            {isFullSideBar ? (
              <Box mr={1}>
                <img alt="Logo" src={IMAGES.APP_LOGO} className={classes.logo} />
              </Box>
            ) : null}
            <Typography className={classes.sidebarTitle} variant="h4">
              Admin Portal
            </Typography>
          </Box>
        </Box>
      </RouterLink>
      <Divider className={classes.divider} />
      <Box p={2}>
        <List>
          {items.map(({ href, icon, title }) => (
            <NavItem
              href={href}
              key={title}
              title={title}
              icon={icon}
              isFullMenu={isFullSideBar}
              data-testid={title.toLowerCase().replace(/ /g, "-") + "-button"}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
      <Box p={2}>
        <List>
          <NavItem
            href={"/logout"}
            title="Logout"
            icon={InputIcon}
            isFullMenu={isFullSideBar}
            data-testid="logout-button"
          />
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden smDown>
        <Drawer
          open
          PaperProps={{ elevation: 1, style: { borderTopRightRadius: theme.shape.borderRadius } }}
          anchor="left"
          variant="persistent"
          onClose={onMobileClose}>
          {content}
        </Drawer>
      </Hidden>
      <Hidden smUp>
        {isFullSideBar && (
          <Drawer
            open
            PaperProps={{ elevation: 24, style: { width: "100%" } }}
            anchor="left"
            variant="temporary">
            {content}
          </Drawer>
        )}
      </Hidden>
    </>
  );
};

export default SideBar;
