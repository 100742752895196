import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { ERROR_CODES, TOKEN_EXPIRED_ERROR_CODE } from "@APP/services/api";
import { getErrorMessageByErrorCode } from "@APP/utils";
import { logout } from "@APP/redux";

import { useAlert } from "..";

const HANDLED_ERROR_CODES = [2321, 5005];

/**
 * A common utility for handling server errors with specific error codes.
 * @returns `handleErrorCodes` function that returns `isHandled` boolean value.
 * A boolean value indicates whether the error was successfully handled by the function.
 */
const useHandleErrorCodes = () => {
  const alert = useAlert();

  const dispatch = useDispatch();
  /**
   * Error handler function.
   * @returns boolean - indicates whether the error was successfully handled by the function.
   */
  const handleErrorCodes = useCallback((errorCode: keyof typeof ERROR_CODES) => {
    const errorCodeForHandling = HANDLED_ERROR_CODES.find((code) => code === errorCode);

    if (errorCodeForHandling) {
      const errorMessage = getErrorMessageByErrorCode(errorCode);

      if (TOKEN_EXPIRED_ERROR_CODE === errorCodeForHandling) {
        dispatch(logout());

        return true;
      }
      alert("Failure", errorMessage, [
        {
          text: "Okay",
        },
      ]);

      return true;
    }

    return false;
  }, []);

  return handleErrorCodes;
};

export default useHandleErrorCodes;
