import { useState } from "react";
import { Box, Input, MenuItem, Select, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Pagination from "@mui/material/Pagination";
import { SelectChangeEvent } from "@mui/material/Select";

const useStyles = makeStyles((theme) => ({
  paginationPanelItem: {
    "&.Mui-selected": {
      backgroundColor: theme.palette.action.active,
      color: theme.palette.secondary.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.action.active,
        opacity: 0.6,
      },
    },
  },
}));

type Props = {
  entries: number;
  rowsPerPageOptions: number[];
  lastPage: number;
  page: number;
  onEntriesChange?: (entries: number) => void;
  onPageChange?: (newPage: number) => void;
};

const PaginationPanel = ({
  entries,
  onEntriesChange,
  rowsPerPageOptions,
  lastPage,
  page,
  onPageChange,
}: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const handleChangeEntries = (event: SelectChangeEvent<number>) => {
    if (onEntriesChange) onEntriesChange(Number(event.target.value));
  };
  const changePage = (_0: any, newPage: number) => {
    if (onPageChange) onPageChange(newPage - 1);
  };

  return (
    <Box p={2} justifyContent="end" display="flex">
      <Box alignItems="center" display="flex" mr={1}>
        <Typography variant="body1">Rows per page:</Typography>
      </Box>
      {!!rowsPerPageOptions.length && (
        <Select
          variant="standard"
          sx={{ underline: "none", borderBottom: "none" }}
          input={<Input disableUnderline />}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={entries}
          onChange={handleChangeEntries}>
          {rowsPerPageOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      )}
      <Pagination
        count={lastPage + 1}
        showFirstButton
        showLastButton
        onChange={changePage}
        page={page + 1}
        classes={{ ul: classes.paginationPanelItem }}
      />
    </Box>
  );
};

export default PaginationPanel;
