import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  Card,
  Container,
  Grid,
  MenuItem,
  Paper,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { format } from "date-fns";
import { useFormik } from "formik";

import { FooterActionsButtons, Page, ScreenHeader, Table } from "@APP/components";
import { ADMIN_ACTIONS_LABEL, mediumFormatDateTime } from "@APP/constants";
import { useAlert, useHandleErrorCodes } from "@APP/hooks";
import { SCREEN_PATHS } from "@APP/navigation";
import { AppState, hideLoader, setSelectedUser, showLoader } from "@APP/redux";
import { API } from "@APP/services";
import { tableStyles as useStyles } from "@APP/styles";
import { ADMIN_ACTIONS, UserSearchResponse } from "@APP/types";
import { formatErrorMessage } from "@APP/utils";

const UserDetailsView = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const alert = useAlert();
  const handleErrorCodes = useHandleErrorCodes();

  const { selectedUser } = useSelector(
    ({
      userAdmin: {
        users: { selectedUser },
      },
    }: AppState) => ({
      selectedUser,
      // usersRTP's endpoint not yet ready
      // usersRTPS,
    }),
  );

  const { userId }: { userId: string } = useParams();

  useEffect(() => {
    if (!selectedUser) fetchUserDetails(userId);
    if (selectedUser) setFieldValue("username", selectedUser.username);
  }, [selectedUser, userId]);

  const fetchUserDetails = async (userId: string) => {
    try {
      dispatch(showLoader());
      const results = await API.getSearchedUsers({
        username: userId,
      });
      const matchedUser = results.data.find((user: UserSearchResponse) => user.username === userId);
      matchedUser ? dispatch(setSelectedUser(matchedUser)) : history.push(SCREEN_PATHS.UPDATE_USER);
    } catch (error) {
      alert("Error", formatErrorMessage(error));
    } finally {
      dispatch(hideLoader());
    }
  };

  /*
  // Waiting on backend to build the endpoint for this to fetch RTP's for a given user, leaving commented out until ready.
  useEffect(() => {
    const fetchRTPS = async () => {
      try {
        dispatch(showLoader())
        const data = await API.getRTPSofUser(userId);
        dispatch(getRTPSofUser(data));
      } catch (error) {
        alert("Error", formatErrorMessage(error));
      }
      dispatch(hideLoader());
    };
    fetchRTPS();
  }, []);
  */

  const handleOnSubmit = async ({
    username,
    action,
  }: {
    username: string;
    action: ADMIN_ACTIONS | string;
  }) => {
    try {
      dispatch(showLoader());
      const blocked = action === ADMIN_ACTIONS.Block ? true : false;

      await API.changeUserBlockStatus(username, blocked);
      history.push(SCREEN_PATHS.UPDATE_USER_SUCCESS);
    } catch (error: any) {
      const errorData = error?.response?.data;
      const isHandled = handleErrorCodes(errorData?.errorCode);

      if (!isHandled) return;
      alert("Error", formatErrorMessage(error));
    } finally {
      dispatch(hideLoader());
    }
  };

  const { handleSubmit, handleChange, values, setFieldValue } = useFormik({
    initialValues: {
      username: "",
      action: "",
    },
    onSubmit: handleOnSubmit,
  });

  if (!selectedUser) return null;

  const {
    customerId,
    username,
    phone,
    meta: { lastLoginTime },
  } = selectedUser;

  const formattedLastLogin = lastLoginTime
    ? format(new Date(lastLoginTime), mediumFormatDateTime)
    : "";

  const renderFirstHeader = () => (
    <TableRow className={classes.tableHead}>
      <TableCell>User Name</TableCell>
      <TableCell>Organisation Name</TableCell>
      <TableCell>Phone Number</TableCell>
      <TableCell />
    </TableRow>
  );

  const renderFirstRow = () => (
    <Fragment key={customerId}>
      <TableRow>
        <TableCell>{username}</TableCell>
        <TableCell>{selectedUser?.orgName ? selectedUser.orgName : ""}</TableCell>
        <TableCell>{phone}</TableCell>
        <TableCell></TableCell>
      </TableRow>
      <TableRow className={classes.tableHead}>
        <TableCell>Last Login</TableCell>
        <TableCell />
        <TableCell />
        <TableCell />
        {/* leaing commented out for now as the BE isn't yet returning this info */}
        {/* <TableCell>Account Status</TableCell> */}
        {/* <TableCell>User Active</TableCell> */}
        {/* <TableCell>Linked Bank Account</TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell>{lastLoginTime ? formattedLastLogin : "N/A"}</TableCell>
        <TableCell />
        <TableCell />
        <TableCell />
        {/* <TableCell>{blocked ? "Blocked" : "Unblocked"}</TableCell> */}
        {/* <TableCell>{userActive ? "Active" : "Inactive"}</TableCell> */}
        {/* <TableCell>{linkedBank ? linkedBank : "None"}</TableCell> */}
      </TableRow>
    </Fragment>
  );

  return (
    <Container maxWidth={false}>
      <Page title="User Details">
        <ScreenHeader title="Update User" />
        <form onSubmit={handleSubmit}>
          <Box my={3}>
            <Card elevation={12}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Table
                    data={[selectedUser]}
                    renderHeader={renderFirstHeader}
                    renderRows={renderFirstRow}
                    showPagination={false}
                  />
                </Grid>
              </Grid>
              <Box p={2}>
                <Grid container item>
                  <Grid item lg={4} xs={false} />
                  <Grid item lg={4} xs={12}>
                    <Paper elevation={4}>
                      <TextField
                        fullWidth
                        select
                        label="Select action"
                        name="action"
                        value={values.action}
                        onChange={handleChange}
                        data-testid="user-actions-select"
                        variant="outlined">
                        {Object.values(ADMIN_ACTIONS).map((action) => (
                          <MenuItem key={action} value={action}>
                            {ADMIN_ACTIONS_LABEL[action]}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Paper>
                  </Grid>
                </Grid>
              </Box>
            </Card>
            <FooterActionsButtons
              backButtonText="Back to Search Results"
              handleBackButton={() => history.push(SCREEN_PATHS.UPDATE_USER)}
              continueButtonText="Apply"
              typeButtonContinue="submit"
            />
          </Box>
        </form>
      </Page>
    </Container>
  );
};

export default UserDetailsView;
