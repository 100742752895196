import { useContext } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";

import { AlertContext } from "./AlertProvider";

const AlertModal = () => {
  const { alertOptions, closeAlert, showAlert } = useContext(AlertContext);
  const { title, message, buttons } = alertOptions ?? {};

  if (!showAlert) return null;

  const handleButtonPress = (buttonIndex: number) => {
    const buttonProps = buttons?.[buttonIndex];
    if (buttonProps && buttonProps.onClick) buttonProps.onClick(buttonProps.text);
    closeAlert();
  };

  return (
    <Dialog open fullWidth maxWidth="xs">
      <DialogTitle>
        <Typography variant="h4">{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {buttons?.length ? (
          buttons.map(({ text }, index) => (
            <Button
              key={`${text}${index}`}
              onClick={() => handleButtonPress(index)}
              color="primary">
              {text ?? "Close"}
            </Button>
          ))
        ) : (
          <Button onClick={closeAlert} color="primary">
            Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AlertModal;
