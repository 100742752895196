import {
  Cell,
  Legend,
  Pie,
  PieChart as RechartsPieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

import { ChartData } from "@APP/types";

import { ChartProps } from "./ChartContainer";
import { ChartLegend, ChartTooltip } from "./components";

const PieChart = ({ data, percent }: ChartProps) => {
  const dataAsChartData = [...(data as ChartData[])];

  return (
    <ResponsiveContainer height="100%" width="100%">
      <RechartsPieChart>
        <Tooltip content={<ChartTooltip percent={percent} />} />
        <Legend
          layout="vertical"
          align="right"
          verticalAlign="middle"
          iconSize={15}
          iconType="rect"
          payload={dataAsChartData}
          content={<ChartLegend percent={percent} data={dataAsChartData} />}
        />
        <Pie data={dataAsChartData} innerRadius={50} paddingAngle={3} dataKey="value">
          {dataAsChartData.map((entry: ChartData, index: number) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
      </RechartsPieChart>
    </ResponsiveContainer>
  );
};

export default PieChart;
