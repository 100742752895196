import makeStyles from "@mui/styles/makeStyles";

export const tableStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  tableHead: {
    backgroundColor: "#f5f6f8",
    "& th:first-child, & td:first-child": {
      borderBottomLeftRadius: "25px",
    },
    "& th:last-child, & td:last-child": {
      borderBottomRightRadius: "25px",
    },
  },
  tableRow: {
    cursor: "pointer",
  },
  tableIcon: {
    marginRight: theme.spacing(1),
  },
  placeholderIcon: {
    marginBottom: theme.spacing(1),
  },
  tHead: {
    backgroundColor: "#f5f6f8",
    "& th": {
      borderBottom: "none",
    },
  },
}));
