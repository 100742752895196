import React, { ChangeEvent, MouseEvent } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Popover,
} from "@mui/material";
import pluralize from "pluralize";

import { setReportFilter } from "@APP/redux";
import { convertStringWithUpperRegisterFirstLetter } from "@APP/utils";

import { ArraySetterPayload, CommonChartButtonProps } from "../chartHeaderButtons";

export type HeaderActionButtonProps = {
  headerButtonText?: string;
};

export type SelectListButtonProps = CommonChartButtonProps & ArraySetterPayload;

const renderChartButtonText = (field?: string, buttonLabel?: string) => {
  const commonLabel = `${convertStringWithUpperRegisterFirstLetter(field)}`;
  const fallbackLabel = "Filter";
  const filterButtonLabel = buttonLabel ? buttonLabel : field ? commonLabel : fallbackLabel;

  const formLabel = field
    ? `Select ${convertStringWithUpperRegisterFirstLetter(pluralize.plural(field))} to view`
    : "Select entries to view";
  const errorLabel = field
    ? `Select at least one ${convertStringWithUpperRegisterFirstLetter(
        pluralize.plural(field),
      )} to view`
    : "Select at least one entry.";

  return {
    filterButtonLabel: filterButtonLabel,
    formLabel: formLabel,
    errorLabel: errorLabel,
  };
};

const SelectListButton = ({
  field,
  filterName,
  buttonLabel,
  dataArray,
  reportName,
  selectedData,
  classes,
}: SelectListButtonProps) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const { filterButtonLabel, formLabel, errorLabel } = renderChartButtonText(field, buttonLabel);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleCheckboxSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;

    const newSelectedData =
      selectedData && checked
        ? [...selectedData, value]
        : selectedData?.filter((item) => item !== value);

    dispatch(
      setReportFilter({
        reportName,
        filterName,
        selectedValues: newSelectedData,
      }),
    );
  };

  const clearResults = () =>
    dispatch(setReportFilter({ reportName, filterName, selectedValues: [] }));

  const handleSelectAll = () =>
    dispatch(setReportFilter({ reportName, filterName, selectedValues: dataArray }));

  return !!dataArray.length && dataArray.length > 1 ? (
    <Box className={classes?.buttonContainer}>
      <Button variant="text" size="small" color="primary" onClick={handleClick}>
        {filterButtonLabel}
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}>
        <Box m={1}>
          <FormControl component="fieldset" required>
            <FormLabel component="legend">{formLabel}</FormLabel>
            <FormGroup>
              {dataArray.map((entry, index) => (
                <FormControlLabel
                  key={index}
                  label={entry}
                  control={
                    <Checkbox
                      onChange={handleCheckboxSelect}
                      checked={selectedData?.includes(entry)}
                      value={entry}
                      name="entry"
                    />
                  }
                />
              ))}
            </FormGroup>
            <FormHelperText>{errorLabel}</FormHelperText>
            <Box className={classes?.actionButtons}>
              <Button type="button" variant="outlined" onClick={handleSelectAll} color="primary">
                Select All
              </Button>
              <Button type="reset" variant="outlined" onClick={clearResults} color="secondary">
                Clear
              </Button>
            </Box>
          </FormControl>
        </Box>
      </Popover>
    </Box>
  ) : null;
};

export default SelectListButton;
